.campaign__image {
  width: auto;
  max-height: 45px;
  max-width: 85px;
  align-self: center;
  margin: auto;
}
.donations__section table {
  font-size: 14px;
}

.donations__section .table thead th {
  font-weight: bold;
}
.rf__info__download {
  margin: 20px 0 0 0;
  font-size: 14px;
}
.donations__filter {
  margin: 0 0 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.donations__filter label {
  margin-right: 10px;
}
.donations__filter select {
  width: 100px;
}
.donations__filter__total_label {
  font-weight: bold;
}
.donations__filter__total_number {
  color: var(--primary-color);
  font-weight: bold;
}

.no__donations__container {
  min-height: 400px;
  display: flex;
  margin: auto;
  background-color: var(--color-bg-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}
.no__donations__container p {
  margin: auto;
}
.rf__annuel__btn {
  margin: 0 20px;
}
.rf__info {
  color: #545454;
  margin-top: 20px;
}
.rf__info i {
  color: var(--text-color);
  margin-right: 10px;
}
.rf__info a {
  color: var(--primary-color);
}
