.profile__nav {
  display: flex;
  color: #333;
  margin-bottom: 20px;
  border-bottom: solid 1px #dddddd;
}

.profile__nav__item {
  padding: 15px 0px;
  cursor: pointer;
  text-align: center;
  width: 250px;

  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #dddddd;
}
.profile__nav__item__pw {
  padding: 15px 0px;

  text-align: center;
  width: 250px;

  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #dddddd;
}
.profile__nav__item-active {
  color: black;
  border-bottom: solid 2px #f36f21;
}

.profile__nav__item-active__pw {
  color: black;

}

.forms__container {
  padding: 0 20px;
  position: relative;
}
.profile__imageinfo__container {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.profile__imageinfo__container .profile__imageinfo__container__inner {
  margin: auto;
  display: flex;
}
.profile__imageinfo__container .profile__imageinfo__container__inner > label {
  margin: auto;
}
.profile__imageinfo__container__inner > label {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  display: flex;
  background: #f3f3f3;
}

.profile_image.profile_image__empty {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  height: unset;
  width: unset;
  object-fit: unset;
}
.profile_image {
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile_image.profile_image__empty + .upload__image {
  display: block;
}
.upload__image {
  display: none;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px #f36f21;
  background: white;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: -20px;
  position: absolute;
  bottom: 0;
  padding: 10px;
}

.profile__info {
  color: #545454;
  margin-top: 20px;
}
.profile__info i {
  color: var(--text-color);
  margin-right: 10px;
}
.profile__info a {
  color: var(--primary-color);
}

@media screen and (max-width: 767px) {
  .profile__imageinfo__container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 576px) {
  .profile__nav {
    display: block;
  }
  .profile__nav__item {
    margin: 0 auto;
  }
}
