.pagination__container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  background: var(--color-bg-white);
  padding: 5px;
  border-radius: 25px;
}
.pagination__actual_total__pages {
  font-weight: bold;
  margin: auto;
  padding: 6px 10px;
  background: var(--primary-color);
  border-radius: 25px;
  color: white;
}
.pagination__actual_total__pages input {
  width: 50px;
  text-align: center;
  padding: 0;
}
