.App {
  display: grid;
  background: var(--color-bg-grey);
}
main {
  margin-top: 65px;
  min-height: calc(100vh - 65px);
  padding: 1px 20px;
}
.main__page__container {
  width: calc(100% - 250px);
  margin-left: 250px;
}

.dark {
  filter: invert(90%);
}
.dark img,
.dark .no__dark {
  filter: invert(90%);
}

/* FORM */
input,
textarea {
  padding: 5px 10px;
  border: solid 1px var(--input-border-color);
  outline: none;
  border-radius: 4px;

  color: var(--text-color);
  background-color: var(--color-bg-grey);
}
input:focus {
  border-color: var(--primary-color);
}
input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
label {
  color: var(--text-color);
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

a,
a:hover {
  text-decoration: none !important;
}

button {
  border-radius: 50px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  outline: none;
}

button:focus {
  outline: none !important;
}

button:hover {
  opacity: 0.7;
  outline: none !important;
}
button:disabled,
button[disabled] {
  opacity: 0.7;
}

.btn__md {
  padding: 15px 40px;
  color: var(--button-primary-text-color);
  background-color: var(--button-primary-bg-color);
  font-size: 14px;
  border-radius: 25px;
}
.btn__sm {
  padding: 10px 30px;
  color: var(--button-primary-text-color);
  background-color: var(--button-primary-bg-color);
  font-size: 13px;
  border-radius: 25px;
}
a.btn__xs {
  text-decoration: none;
  font-weight: bold;
  padding: 6px 15px;
}
.btn__xs {
  padding: 4px 15px;
  color: var(--button-primary-text-color) !important;
  background-color: var(--button-primary-bg-color);
  font-size: 12px;
  border-radius: 25px;
}

.form__group {
  width: 100%;
  margin-bottom: 20px;
}
.form__control {
  margin-top: 1px;
  width: 100%;
}

.paper {
  background-color: var(--color-bg-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 20px 0;
}

*:disabled {
  cursor: not-allowed;
}

section {
  margin: 50px 0;
}

.box__shadow {
  box-shadow: var(--box-shadow);
}

.radius__topleft {
  border-top-left-radius: 10px;
}
.radius__topright {
  border-top-right-radius: 10px;
}
.radius__bottomleft {
  border-bottom-left-radius: 10px;
}
.radius__bottomright {
  border-bottom-right-radius: 10px;
}

.col__2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  justify-content: space-between;
}
.col__3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  justify-content: space-between;
}

h2.page__title {
  font-size: 16px;
  text-transform: uppercase;
  color: var(--grey-light-dark-color);
  font-weight: bold;
  display: inline-block;
}
.btn__voir_plus {
  font-weight: bold;
  background-color: transparent;
  outline: none;
  float: right;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
}
.btn__voir_plus:hover {
  color: inherit;
  text-decoration: none;
}
.btn__voir_plus__icon {
  margin-left: 10px;
  color: var(--primary-color);
}
@media screen and (max-width: 767px) {
  .col__2,
  .col__3 {
    grid-template-columns: 1fr;
  }
  .search__input {
    max-width: 170px;
  }
}

.loading__container {
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: var(--color-bg-white);
}
.table-text-dark {
  color: var(--table-text-color) !important;
}
.table-bg-white {
  background-color: var(--color-bg-white) !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--table-stripped-odd-bg-color) !important;
}

.simple_link {
  color: inherit;
  text-decoration: none;
}

.simple_link:hover {
  color: #f36f21;
  text-decoration: none;
}

.font-bold {
  font-weight: bold;
}
.font-14 {
  font-size: 14px;
}
