.login__page__container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.login__page__container__inner {
  margin: 10vh auto auto;
  width: 300px;
}

.login__page__form__container {
  background-color: white;
  box-shadow: 0 1px 5px 0px grey;
  border-radius: 4px;
  padding: 20px;
}
