:root {
  --color-bg-white: #ffffff;
  --color-bg-grey: #f3f3f3;

  --border-grey-color: #ebebeb;

  --text-color: #333333;

  --button-primary-bg-color: #f36f21;
  --button-primary-text-color: #ffffff;
  --button-secondary-bg-color: #ffffff;
  --button-secondary-text-color: #333333;

  --box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

  --primary-color: #f36f21;
  --success-color: #3ecd99;
  --grey-color: #f3f3f3;
  --grey-light-color: #fafafa;
  --grey-light-dark-color: #969696;
  --grey-dark-color: #7f7f7f;

  --input-border-color: #dddddd;
  --table-stripped-odd-bg-color: #fafafa;
  --table-text-color: #343a40;
}
[theme="dark-mode"] {
  --color-bg-white: #050505;
  --color-bg-grey: #111111;

  --border-grey-color: #111111;

  --text-color: #c9c8c9;

  --grey-color: #333333;

  --box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

  --input-border-color: #232323;
  --table-stripped-odd-bg-color: #131313;
  --table-text-color: #c9c8c9;
}

/* Font icon (using Font Awesome as an example) */
[theme="dark-mode"] .drawer__openclose__btn .fa,
[theme="dark-mode"] .notification__icon_cont .fa {
  color: var(--text-color);
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Raleway", "Roboto",
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  transition: all 0.1s, font-size 0s;
  transition-timing-function: ease-in;
}
