nav {
  display: flex;
  /* padding: 10px; */
  background-color: var(--color-bg-white);
  box-shadow: var(--box-shadow);
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 260px;
  z-index: 8;
}
.first__element__nav__container {
  display: flex;
  padding: 10px;
}
.second__element__nav__container {
  padding: 10px;
  border-left: solid 1px #ececec;
}
.dot__separation {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}
.search__input {
  padding: 5px 5px 5px 40px;
  margin-left: 20px;
  border: unset;
  color: var(--text-color);
  font-size: 14px;
  background: var(--color-bg-grey);
  border-radius: 25px;
  width: 250px;
  height: 100%;
}
.search__container {
  position: relative;
}
.icon__search {
  width: 25px;
  position: absolute;
  left: 30px;
  top: 7px;
  margin: auto;
}
.user__icon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.user__icon img {
  border-radius: 50%;
  /* border: solid 2px #f36e20; */
  width: 40px;
  height: 40px;
}
.user__icon i {
  font-size: 0.8em;
  color: var(--primary-color);
}
.user__icon img,
.user__icon span {
  vertical-align: middle;
  margin-left: 10px;
}

.drawer__openclose__btn {
  display: flex;
  cursor: pointer;

  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none !important;
}
.drawer__openclose__btn i {
  margin: auto 5px;
  font-size: 1.5em;
  outline: none;
}

.close__drawer {
  margin-left: -250px;
}
.open__drawer {
  margin-left: 0;
}

.close__drawer + .drawer__overlay__mobile {
  display: none;
}

.close__drawer + .main__page__container {
  width: 100%;
  margin-left: 0;
}

.close__drawer + .main__page__container nav {
  padding-left: 10px;
}

@media screen and (max-width: 992px) {
  .dropdown__notif__content {
    min-width: 250px !important;
  }
  /* nav {
    padding-left: 10px;
  } */
  .close__drawer .drawer__overlay__mobile {
    display: none;
  }
  .open__drawer .drawer__overlay__mobile {
    display: block;
  }
  /* .drawer__container {
    display: none;
  } */
  .main__page__container {
    width: 100%;
    margin-left: 0;
    max-width: 100vw;
  }

  .user__icon img,
  .user__icon span {
    margin-left: 5px;
  }
  /* .user__icon > span {
    display: none;
  } */
  .main__page__container nav {
    padding-left: 10px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
  background-color: var(--color-bg-white);
  color: var(--text-color);
  min-width: 150px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 1;
  border-radius: 4px;
}
.dropdown:hover .dropdown__content {
  display: block;
  width: max-content;
  padding: 10px 0;
}

.dropdown__content > span {
  margin: 0;
  padding: 10px;
}
.dropdown__content > div {
  margin: 0;
}
.dropdown__content > div > span:hover,
.dropdown__content > div > a:hover {
  background-color: var(--color-bg-grey);
}
.dropdown__content > div > span,
.dropdown__content > div > a {
  margin: 0;
  width: 100%;
  display: block;
  padding: 5px 15px;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

/* ------------------------------------------- */

.donor__switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 0;
}

.donor__switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.donor__dl__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.donor__dl__slider:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f186";
  font-size: 20px;
  text-align: center;
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0;
  bottom: 0;
  color: #fff;
  background-color: #333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .donor__dl__slider {
  background-color: var(--primary-color);
}
input:checked + .donor__dl__slider:before {
  content: "\f185";
  color: #fff;
  background-color: #333;
}

input:focus + .donor__dl__slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .donor__dl__slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.donor__dl__slider.donor__dl__round {
  border-radius: 25px;
  margin-left: 0;
}

.donor__dl__slider.donor__dl__round:before {
  border-radius: 50%;
}

.dropdown__notif {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown__notif__content {
  display: none;
  position: absolute;
  background-color: var(--color-bg-white);
  color: var(--text-color);
  right: -50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  border-radius: 4px;
  padding: 10px 0;
}
.dropdown__notif:hover .dropdown__notif__content {
  display: block;
  width: max-content;
}
/* .dropdown__notif__content > div {
  margin: 0;
  padding: 5px;
  border-bottom: solid 1px #ccc;
} */
/* .dropdown__notif__content > div:hover {
  background-color: var(--color-bg-grey);
} */

/* .dropdown__notif__content > div {
  margin: 0;
}
.dropdown__notif__content > div > span:hover,
.dropdown__notif__content > div > a:hover {
  background-color: var(--color-bg-grey);
}
.dropdown__notif__content > div > span,
.dropdown__notif__content > div > a {
  margin: 0;
  width: 100%;
  display: block;
  padding: 5px 15px;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
} */
