.table__head {
  /* background-color: #9e9e9e;
  color: #ffffff; */
  border-bottom: solid 1px #f3f3f3;
}
.table__head th {
  padding: 20px 0.75em 20px;
  font-weight: unset;
}
.donations__section table {
  border-radius: 10px;
}
table {
  border-radius: 4px;
}
