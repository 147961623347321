.notif__container div {
  margin: 0;
  padding: 8px 5px;
  border-bottom: solid 1px #ccc;
}
.notif__container div.unopened {
  background-color: var(--color-bg-grey);
}
.notif__container a:last-child div {
  border-bottom: none;
}
.notif__container > div:hover {
  opacity: 0.4;
}
.notif__container {
  font-size: 0.9em;
  font-weight: 500;
  text-transform: capitalize;
  min-width: 300px;
  max-width: 300px;
  min-height: 200px;
  max-height: 250px;
  overflow-y: auto;
}
