.project__container {
  position: relative;
  /* display: grid;
  grid-template-columns: 190px calc(100% - 170px); */
  background-color: var(--color-bg-white);
  color: var(--text-color);
  border-radius: 10px;
  padding: 20px 20px 20px 0px;
  -webkit-box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  margin: 0 0 20px 0;
}

.project__image_container {
  text-align: center;
}

.project__image_container img {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}

/* .project__info_container {
} */
.project__info_container_inner {
  display: flex;
}
.project__info_title_desc {
  width: 50%;
  padding-right: 10px;
}
.project__info_title_desc h3 {
  font-size: 18px;
  font-weight: bold;
}
.project__info__status__badge {
  font-size: 13px;
  padding: 3px 10px;
  background: var(--button-primary-bg-color);
  border-radius: 25px;
  color: var(--button-primary-text-color);
  white-space: nowrap;
  margin-left: 10px;
}
.project__ended__bg {
  background-color: var(--success-color) !important;
}
.project__ended__color {
  color: var(--success-color) !important;
}
.project__info_title_desc p {
  font-size: 13px;
}
.project__info_percentage {
  width: 50%;
  padding-left: 10px;
  padding-right: 20px;
}
.project__progress_bar {
  position: relative;
  background-color: var(--grey-color);
  border-radius: 25px;
  height: 8px;
  width: 100%;
}
.project__progress {
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 25px;
  height: 8px;
}
.project__info__amount__collected,
.project__info__amount__goal {
  /* margin-bottom: 10px; */
  font-size: 15px;
}
.project__info__amount__collected b {
  color: var(--primary-color);
}
.project__info__amount__collected__percentage {
  /* float: right; */
  font-size: 24px;
  font-weight: bold;
}
.project__info__amount__goal {
  color: var(--grey-dark-color);
  font-weight: bold;
}

/* .project__state_container {
  width: 100%;
  padding-right: 20px; 
} */
.project__status_line__cont {
  height: 50px;
  margin: 25px auto;
  display: flex;
}
.project__status_line {
  /* width: 100%; */
  background-color: var(--grey-color);
  border-radius: 5px;
  height: 5px;
  position: relative;
  margin: auto;
  padding: 0;
}
.project__status_line_active {
  position: absolute;
  background-color: var(--primary-color);
  height: 5px;
  border-radius: 25px;
}
.project__state__icon {
  position: absolute;
  width: 50px;
  line-height: 0.5em;
  text-align: center;
}
/* .project__state__icon:first-child img {
  margin-bottom: 0;
} */

.project__state__icon img {
  margin-top: -23px;
  width: 50px;
}

.project__step__title {
  font-size: 10px;
  font-weight: bold;
  line-height: 1em;
}

.project__status_line_ended {
  padding-right: 0;
  margin-top: 20px;
}

.project___status__ended_details_cont {
  font-size: 13px;
  padding: 0;
}
.project___status__ended_details_cont b {
  color: #ec6122;
  font-size: 16px;
}
.actualstep__dropShadow {
  width: 40px !important;
  margin-top: -19px !important;
}

.project__container:hover > .project__options {
  display: block;
}

.project__options {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9;
}

.project__options:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.project__options > i {
  padding: 10px 10px 0 10px;
  height: 35px;
  width: 35px;
  text-align: center;
  cursor: pointer;
}
.project__options__list {
  position: absolute;
  top: 34px;
  right: 0;
  background-color: var(--color-bg-white);
  border-radius: 4px;
  width: 150px;
  padding: 5px 0;
  box-shadow: var(--box-shadow);
  display: none;
}
.project__options__list a {
  width: 100%;
  display: inline-block;
  padding: 5px;
  font-weight: 500;
  text-decoration: none;
  color: inherit;
}
.project__options__list a:hover {
  background-color: rgba(0, 0, 0, 0.2);
  /* color: wheat; */
}
.project__options:hover .project__options__list {
  display: block;
}

.project_details__container {
  margin-top: 50px;
  padding: 20px;
}
.project_details__image__container,
.project_details__progress__container,
.project_details__infos_container,
.project_details__desc_container,
.project_details__livret_container,
.project_details__media_container {
  margin: 20px 0;
}
.project_details__media_container {
  padding: 20px;
}
.media__lib__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  gap: 1em;
  justify-content: space-around;
}
.media__lib__container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.media__lib__container img:hover {
  opacity: 0.7;
  cursor: pointer;
}
.project_details__infos_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media all and (max-width: 767px) {
  .project__state__icon:nth-child(odd) img {
    margin-bottom: 20px;
  }
  .project__container {
    padding: 20px 0px 20px 0px;
  }
  .project_details__infos_container {
    grid-template-columns: 1fr;
  }
  .media__lib__container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
  }
  .media__lib__container img {
    width: 100px;
    height: 100px;
  }
  .project__image_container img {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 576px) {
  .media__lib__container {
    grid-template-columns: repeat(auto-fit, minmax(90px, 90px));
  }
  .media__lib__container img {
    width: 90px;
    height: 90px;
  }
}
.project_details__progress__container {
  margin: 20px 0 60px;
}
.project_details__title_status {
  display: grid;
  grid-template-columns: 3fr auto;
  align-items: center;
}
.project_details__title {
  display: inline-block;
}
.project_details__image {
  width: auto;
  max-width: 100%;
  height: 200px;
  border-radius: 4px;
  object-fit: cover;
}

.image__preview__container {
  position: fixed;
  z-index: 9;

  display: grid;
  grid-template-rows: 10vh 90vh;
  grid-template-columns: 1fr;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);
}

.image__close__container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.image__preview__image__to__preview {
  height: auto;
  width: auto;
  max-height: 80vh;
  max-width: 100%;
  object-fit: cover;
  z-index: 999;
  margin: auto;
}

.image__preview__donwload__close__container {
  height: 10vh;
  color: white;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.image__preview__donwload__close__container > span,
.image__preview__donwload__close__container > a {
  margin: auto 10px;
  font-size: 1.5em;
  font-weight: 200 !important;
  z-index: 999;
  padding: 10px;
  cursor: pointer;
  color: var(--button-primary-text-color);
}

.no__project__container {
  min-height: 400px;
  display: flex;
  margin: auto;
  background-color: var(--color-bg-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}
.no__project__container p {
  margin: auto;
}
