.dash__section__inner {
  width: 100%;
  height: max-content;
  /* min-height: 200px; */
  position: relative;
  border-radius: 10px;
}
.two__col {
  display: grid;
  grid-template-columns: 60% 38%;
  column-gap: 25px;
  justify-content: space-between;
}
.one__col {
  /* background: white; */
  border-radius: 10px;
}
.dash__section__inner__left {
  width: 100%;
  min-height: 100px;
}
.dash__section__inner__right {
  width: 100%;
  /* min-height: 230px; */
  border-radius: 10px;
  position: relative;
  padding: 00px;
}
.dash__section__inner__right__ws {
  /* background-image: linear-gradient(
    to left,
    #3e84e9,
    #0098f0,
    #00aaf1,
    #00baed,
    #00c9e7
  ); */
  background-image: linear-gradient(
    to left top,
    #3e84e9,
    #0098f0,
    #00aaf1,
    #00baed
  );
}
.dash__section__inner__right__wsShape {
  padding: 40px;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 120px;
  height: 100%;
  width: 100%;
}

.dash__section__inner__left__inner,
.dash__section__inner__right__inner {
  border-radius: 10px;
  background: var(--color-bg-white);
  min-height: 228px;
  padding: 20px 5px;
  max-width: 100% !important;
}

.dash__section__title {
  font-weight: bold;
  font-size: 24px;
}

.next__event__info {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.next__event__img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 10px;
}
.next__event__eltitle {
  margin: 5px 0 20px;
  padding: 0;
  color: white;
  font-weight: bold;
  font-size: 38px;
  line-height: 1em;
}
.next__event__elSlogan {
  margin: 5px 0 20px;
  padding: 0;
  color: white;
  font-size: 28px;
  line-height: 1em;
}
.next__event__elCamp {
  margin: 5px 0;
  padding: 0;
  color: white;
  font-size: 24px;
  line-height: 1em;
}
.next__event__btn {
  background-color: white;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 50px;
  cursor: pointer;
}

.next__event__btn:hover {
  text-decoration: none;
  color: inherit;
  opacity: 0.9;
}

.btn__angle__right {
  color: #f36e20;
  font-size: 18px;
  margin-left: 10px;
}
.collect__month__container {
  position: relative;
  min-height: 230px;
  height: max-content;
  border-radius: 10px;
  background-size: cover;
  background-color: var(--color-bg-white);
  padding: 40px;
}
.collect__month__info {
  width: 60%;
}
.collect__month__title {
  color: white;
  font-weight: bold;
  font-size: 24px;
}
.collect__month__description {
  color: white;
  font-size: 14px;
  line-height: 1em;
}

.collect_progress__bar {
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  height: 5px;
  width: 100%;
}
.collect__progress {
  position: absolute;
  background-color: #ffffff;
  border-radius: 25px;
  height: 5px;
}

.collect__month__btn_container {
  position: absolute;
  display: grid;
  grid-template-columns: 70% 30%;
  bottom: 30px;
  /* display: inline-block; */
  width: calc(100% - 80px);
}
.collect__ws__btn_container {
  display: inline-block;
}
.collect__month__progress {
  margin: auto 0;
}
.collect__month__progress p {
  margin-bottom: 5px;
}
.collect__month__btn {
  background-color: var(--button-secondary-bg-color);
  color: var(--button-secondary-text-color);
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 7px 20px;
  border-radius: 50px;
  display: inline-block;
  margin: auto 0 0 auto;
}
.collect__month__btn:hover {
  text-decoration: none;
  color: inherit;
}

.dash__section__ws__container {
  width: 70%;
  /* display: grid;
  grid-template-columns: 70% 30%; */
}
.dash__section__ws__image {
  width: 150px;
  max-width: 100%;
}
.collect__ws__btn {
  background-color: var(--button-secondary-bg-color);
  color: var(--button-secondary-text-color);
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 7px 20px;
  border-radius: 50px;
  text-decoration: none;
  display: inline-block;
}
.collect__ws__btn:hover {
  text-decoration: none;
  color: inherit;
}
.collect__ws__txt {
  font-size: 18px;
  font-weight: bold;
  color: white;
}
.dash__section__ws__text__container {
  margin: auto;
}

.badge__container {
  display: grid;
  gap: 1em;
  position: relative;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  min-height: 250px;
}

.badge__item {
  margin: auto;
  position: relative;
}
.badge__item .share__overlay {
  display: none;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 5px;
}
.share__overlay div {
  display: grid;
  grid-template-columns: 30px 30px;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  text-align: center;
}
.share__overlay a {
  display: block;
}
.share__overlay p {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.badge__item .share__overlay i {
  font-size: 1.5em;
}

.welcome__badge__msg {
  position: absolute;
  background: #ff6c61;
  padding: 10px;
  border-radius: 4px;
  color: white;
  top: -60px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}

.badge__item > img {
  width: 100px !important;
}
/* .badge__item > img.badge__atributed:hover {
  transform: scale(1.3);
  cursor: pointer;
} */
.badge__atributed a {
  color: inherit;
  text-decoration: none;
}
.badge__atributed .fa-facebook {
  cursor: pointer;
  color: #4267b2;
}
.badge__atributed .fa-twitter {
  cursor: pointer;
  color: #1da1f2;
}
.badge__atributed .fa-pinterest {
  cursor: pointer;
  color: #e60023;
}
.badge__item.badge__atributed:hover .share__overlay {
  display: block;
}
.lastThreeDonations__container {
  display: grid;
  font-size: 14px;
  padding: 15px 0;
  grid-template-columns: 1fr 5fr 2fr;
  border-bottom: solid 1px var(--border-grey-color);
}

.lastThreeDonations__container > span {
  margin: auto 5px;
}

.lastThreeDonations__num {
  margin: auto !important;
  color: white;
  background: var(--primary-color);
  text-align: center;
  width: 20px;
  height: 20px;
  -ms-transform: rotate(45deg); /* Internet Explorer */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari et Chrome */
  -o-transform: rotate(45deg); /* Opera */
  transform: rotate(45deg);
  border-radius: 2px;
  font-weight: bold;
}
.lastThreeDonations__num span {
  display: inline-block;
  -ms-transform: rotate(-45deg); /* Internet Explorer */
  -moz-transform: rotate(-45deg); /* Firefox */
  -webkit-transform: rotate(-45deg); /* Safari et Chrome */
  -o-transform: rotate(-45deg); /* Opera */
  transform: rotate(-45deg);
}
.lastThreeDonations__camp {
  color: #333;
}
.lastThreeDonations__camp .lastThreeDonations__camp__date {
  font-size: 13px;
  color: #646464;
}
.lastThreeDonations__camp .lastThreeDonations__camp__name {
  font-weight: bold;
}
.lastThreeDonations__date {
  color: #333;
  font-weight: bold;
}
.lastThreeDonations__amount {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.lastThreeDonations__container:nth-last-child(1) {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.lastThreeDonations__container:nth-child(1) {
  margin-top: 0;
  padding-top: 0;
}
/* .custom__carousel .custom__carousel__item {
  display: none;
}
.custom__carousel .custom__carousel__item.custom__carousel__item-active {
  display: block;
} */
.custom__carousel__pagination {
  position: absolute;
  bottom: 0;
  margin: 5px auto;
  width: 100%;
}
.custom__carousel__pagination__item {
  margin: 0 5px;
  cursor: pointer;
  font-size: 13px;
}
@media screen and (max-width: 1140px) and (min-width: 768px) {
  .badge__container {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)) !important;
  }
  .badge__item > img {
    width: 80px !important;
  }
  .dash__section__ws__container {
    width: 100% !important;
    margin: auto 0;
  }
  .collect__ws__btn_container {
    position: absolute !important;
    bottom: 40px !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    display: flex !important;
    width: unset !important;
  }
  .collect__ws__btn {
    margin: auto;
  }
  .collect__ws__txt {
    text-align: center;
  }
}
@media screen and (max-width: 1140px) {
  .share__overlay div {
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  }
  .badge__item .share__overlay i {
    font-size: 1em;
  }
  .collect__month__btn_container {
    position: relative;
    bottom: unset;
    display: block;
    left: 0 !important;
  }
  .collect__month__progress {
    margin: auto 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .collect__month__progress p {
    margin-top: 5px;
  }
  .collect__ws__btn_container {
    position: absolute !important;
    bottom: 40px !important;
    left: 40px !important;
    display: inline-block !important;
  }
  .one__col,
  .two__col {
    grid-template-columns: 1fr;
  }
  .dash__section__inner__left,
  .dash__section__inner__right {
    margin-bottom: 20px;
  }
  .dash__section__inner__left__inner,
  .dash__section__inner__right__inner {
    min-height: unset !important;
  }
  .collect__month__container {
    padding: 10px 20px;
  }
  .collect__month__info {
    width: 100%;
  }
  .next__event__img {
    width: 100%;
    object-fit: cover;
    height: 250px;
  }
  .next__event__eltitle {
    font-size: 28px;
  }
  .next__event__elSlogan {
    font-size: 18px;
  }
}
