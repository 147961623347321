.forgotpass__container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}
.form__container {
  margin: 15vh 10px;
  width: 300px;
}
.form__container__inner {
  background: #ffffff;
  box-shadow: 0 1px 5px 0px grey;
  padding: 20px;
  border-radius: 4px;
}
.fp__page__title {
  font-size: 1.7em;
  margin-bottom: 22px;
}
.margin-10-15 {
  margin: 10px 15px;
}
