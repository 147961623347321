.drawer__container {
  z-index: 9;
}
.drawer__container__inner {
  background: #000;
  color: white;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  height: 100%;
  position: fixed;
  width: 250px;
  z-index: 99;
  box-shadow: var(--box-shadow);
  background-image: url("../../assets/images/drawerBgShape.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.drawer__logo__container {
  padding: 10px;
  margin: auto;
  display: flex;
}
.drawer__logo__container a {
  margin: auto 20px;
}
.drawer__logo__container img {
  width: 75px;
}
.drawer__items__container {
  min-height: 50vh;
  display: grid;
}
.drawer__items__container__inner {
  margin: 5vh 0;
}
.drawer__item {
  /* display: flex; */
  padding: 10px 0px 10px 15px;
  margin: 5px 15px 5px 0;
}
.drawer__item div {
  display: inline-block;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 2px;
}
.drawer__item img {
  max-width: 15px;
  max-height: 15px;
}
.drawer__item:hover {
  opacity: 1;
  background: var(--primary-color);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
/* .drawer__item:hover img {
  opacity: 0.7;
} */
.drawer__item__link {
  text-decoration: none;
  color: inherit;
}

.drawer__item__link:hover {
  text-decoration: none;
  color: white;
}
.drawer__item__link.drawer__item__link-active {
  text-decoration: none;
  color: white;
}
.drawer__item__link.drawer__item__link-active .drawer__item {
  background: var(--primary-color);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
.drawer__item span {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
/* .drawer__item i,
.drawer__item img {
   margin-right: 10px; 
  font-size: 20px; 
} */

.drawer__logout__container {
  display: grid;
  bottom: 10px;
  position: absolute;
  width: 100%;
}
.drawer__logout__container__inner {
  margin: auto 0;
  cursor: pointer;
}
.drawer__overlay__mobile {
  display: none;
}
@media screen and (max-width: 991px) {
  .drawer__overlay__mobile {
    position: fixed;
    z-index: 99;

    background-color: rgba(0, 0, 0, 0.4);

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
