.modal__container {
  position: fixed;
  width: 400px;
  max-width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  /* background-color: white;
  box-shadow: 0 1px 5px 0px grey; */
  border-radius: 4px;
  padding: 20px 10px 10px;
  border: solid 1px #c6c6c6;
  z-index: 99;
}

.btn__close {
  font-weight: 400;
  position: absolute;
  right: 0;
  top: -13px;
  font-size: 33px;
  color: #333;
  background-color: transparent;
}

.modal__container i {
  margin-right: 10px;
}

.modal__container__pw {
  position: fixed;
  width: 40em;
  max-width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  /* background-color: white;
  box-shadow: 0 1px 5px 0px grey; */
  border-radius: 4px;
  padding: 20px 10px 10px;

  z-index: 99;
}